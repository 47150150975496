
import { defineAsyncComponent } from 'vue'
import { mapGetters } from 'vuex'

export default {
  name: 'DocumentsIndexPage',

  components: {
    DocumentsList: defineAsyncComponent(() => import('@/components/account/Documents/DocumentsList.vue')),
    PDEIcon: defineAsyncComponent(() => import('@/components/pde/PDEIcon.vue')),
    PDEMessageBox: defineAsyncComponent(() => import('@/components/pde/PDEMessageBox.vue')),
    PDESkeleton: defineAsyncComponent(() => import('@/components/pde/PDESkeleton.vue')),
    PDESvg: defineAsyncComponent(() => import('@/components/pde/PDESvg.vue')),
  },

  computed: {
    ...mapGetters('account', ['isFullUser', 'isPartialUser']),
    ...mapGetters('documents', ['isLoading', 'requested']),
  },
}
